:root {
  --background: #003033;
  --background-secondary: #3b593b;
  --background-popover: #006168;
  --foreground: white;
  --overlay-background: rgba(45 0 0 / 0.3);
  --highlight-background: #7dacaf;
  --highlight-foreground: #013530;
  --border-color: #53808359;
  --text-color: white;
  --text-color-secondary: #351100;
  --header-text-color: #01232c;
  --field-background: #010b0c;
  --field-text-color: #6a9599;
  --focus-ring-color: #007e5e;
  --link-color: #520055;
  --link-color-secondary: #442100;
  --link-color-pressed: #797979;
  --close-background-color: rgb(255 255 255 / 27%);
  --trigger-width: "max-content";
  --line-size: 1px;
  --delay1: 0.5s;
  --delay2: 0.9s;
  --distance: -1px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  min-width: 200px;
  color: var(--foreground);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#divBox {
  width: calc(100vw - 3em);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: auto;
}
.squareRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-top: 1px solid var(--border-color);
}
.squareRow:first-of-type {
  border-top: 0;
}
.squareRow:last-child {
  border-right: 0;
}
.square {
  min-width: 9.8vw;
  min-height: 2em;
  height: max-content;
  border-right: 1px solid var(--border-color);
}
.table {
  display: flex;
  width: 100%;
  border: 1px solid var(--border-color);
  background-image: url("./resources/svg/table.svg");
  justify-content: center;
  align-items: center;
  font-size: 2em;
  font-weight: 600;
  color: var(--text-color-secondary);
  border-radius: 0.5em;
}
#infoBox {
  display: none;
  width: 80vw;
  height: 80vh;
  margin: 5vh 5vw 5vh 5vw;
  z-index: 10;
  position: absolute;
  background: var(--background-secondary);
  color: var(--foreground);
  padding: 5vh 5vw 5vh 5vw;
}
#closeBox {
  position: absolute;
  background-color: var(--close-background-color);
  border-radius: 1.5em;
  top: 0.5em;
  right: 1.5em;
  padding: 0.5em 1em;
}
#closeBox::before {
  content: "X";
  color: var(--text-color-secondary);
  font-size: 2em;
  font-weight: 600;
}
.locked::after {
  content: url("./resources/svg/lock.svg");
  opacity: 0.7;
}
form.react-aria-Form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.react-aria-TextField {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.react-aria-Input {
  border-radius: 1em;
  padding: 1em;
}
.react-aria-Label {
  text-transform: uppercase;
}

.Dniwja_spectrum-ActionButton {
  text-transform: uppercase;
  background-color: var(--foreground);
  border-radius: 0.5em;
  color: var(--background);
  font-weight: 600;
  padding: 1em;
  width: 100%;
  cursor: pointer;
  transition: all 1s;
  &[disabled] {
    background-color: var(--field-background);
    color: var(--link-color-pressed);
  }
}
.room-overlay {
  position: fixed;
  inset: 0;
  background: var(--overlay-background);
  backdrop-filter: blur(10px);

  &[data-entering] {
    animation: room-modal-blur 300ms;
  }

  &[data-exiting] {
    animation: room-modal-blur 300ms reverse ease-in;
  }
}

.room-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 300px;
  background: var(--overlay-background);
  outline: none;
  border-left: 1px solid var(--border-color);
  box-shadow: -8px 0 20px rgba(0 0 0 / 0.1);

  &[data-entering] {
    animation: room-modal-slide 300ms;
  }

  &[data-exiting] {
    animation: room-modal-slide 300ms reverse ease-in;
  }
}
.react-aria-ComboBox {
  color: var(--text-color);

  .react-aria-Input {
    margin: 0;
    font-size: 1.072rem;
    background: var(--field-background);
    color: var(--field-text-color);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    padding: 0.286rem 2rem 0.286rem 0.571rem;
    vertical-align: middle;

    &[data-focused] {
      outline: none;
      outline: 2px solid var(--focus-ring-color);
      outline-offset: -1px;
    }
  }

  .react-aria-Button {
    background: var(--highlight-background);
    color: var(--highlight-foreground);
    forced-color-adjust: none;
    border-radius: 4px;
    border: none;
    margin-left: -1.714rem;
    width: 1.429rem;
    height: 1.429rem;
    padding: 0;
    font-size: 0.857rem;
    cursor: default;

    &[data-pressed] {
      box-shadow: none;
      background: var(--highlight-background);
    }
  }
}

.react-aria-Popover[data-trigger="ComboBox"] {
  width: var(--trigger-width);
  background: var(--background-popover);
  border-radius: 1em;

  .react-aria-ListBox {
    display: block;
    width: unset;
    max-height: inherit;
    min-height: unset;
    border: none;

    .react-aria-Header {
      padding-left: 1.571rem;
      color: var(--header-text-color);
      font-weight: 600;
      margin-left: 1em;
      font-size: 1.2em;
    }
  }

  .react-aria-ListBoxItem {
    padding: 0.286rem 0.571rem 0.286rem 1.571rem;

    &[data-focus-visible] {
      outline: none;
    }

    &[data-selected] {
      font-weight: 600;
      background: unset;
      position: relative;
      color: var(--text-color);
    }
    &[data-selected]::before {
      content: "✓";
      content: "✓" / "";
      alt: " ";
      position: absolute;
      top: 4px;
      left: 4px;
    }

    &[data-focused],
    &[data-pressed] {
      background: var(--highlight-background);
      color: var(--highlight-foreground);
    }
  }
}
@keyframes room-modal-blur {
  from {
    background: rgba(45 0 0 / 0);
    backdrop-filter: blur(0);
  }

  to {
    background: rgba(45 0 0 / 0.3);
    backdrop-filter: blur(10px);
  }
}

@keyframes room-modal-slide {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}
